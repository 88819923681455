/** @jsx jsx */
import { jsx } from '@emotion/core'
import style from './style';
import React from 'react';

import utils from '../../utils';
import cmsUtils from '../../cms/utils/cmsUtils';
import FsLightbox from 'fslightbox-react';

export function PhotoGallery(props){
    const item = props.item;
    const cmsOption = props.cmsOption || {};
    const cssClass = utils.classNames('cms_item', 'photoGallery', item.cssClass || item.anchorName || '');
    const _images = cmsUtils.payload(item, 'ImageUrl') ? cmsUtils.payload(item, 'ImageUrl').split("|") : [];
    const [toggler, setToggler] = React.useState(false);

    return(
        <div css={style.photoGallery} className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}>
            <FsLightbox
                toggler={ toggler }
                sources={[_images]}
            />
            <div css={style.thumbnails}>
                {
                    _images.map((item, index) =>
                     <div css={style.thumbnail} className="thumbnail" key={index}>
                         <a href style={{cursor:'pointer'}} onClick = {(e) => {
                             //console.log("vds");
                             e.preventDefault();
                             e.stopPropagation();
                             setToggler(!toggler);
                         }}>
                             <img src={item} alt="image_1"/>
                         </a>
                    </div>)
                }
            </div>
        </div>
    );   
}