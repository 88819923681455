import { css  } from '@emotion/core'
import { mq } from '../../cssInJs'

export default {
  top: css(mq({
    //height: [44, null, 80],
    //height: [44, null, 56],
    height: [44,55,65,70,80,90,110],
    //background: 'black',
    position: 'fixed',
    width: '100%',
    zIndex: '999',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'all 0.3s',
    backgroundImage:'url(/assets/logos/top-masthead.png)',
    backgroundSize:'cover',
    backgroundRepeat:'no-repeat',
    backgroundPosition:'bottom'
  })),
  // topScrolled: css(mq({
  //   height: [44, null, 56],
  // })),
  logo: css(mq({
  })),
  logoImg: css(mq({
    display: 'block',
    //height: [22, null, 36],
    height: [70,null,80,90,100,110],
    //marginTop: ['3rem','3rem','3rem','3rem','3rem','3rem']
    marginTop:'3rem'
  })),
  // logoScrolled: css(mq({
  // })),
}