//import React, { useLayoutEffect, useRef } from 'react';
import React from 'react';
//import { useRouter } from '../../components';
//import { SiteLink } from '../../components';
//import BottomDrawer from '../../components/bottomDrawer'

export function BottomDock(props) {
  // const links = [
  //   { url: '/products', title: 'PRODUCTS' },
  //   { url: '/contact', title: 'CONTACT US' },
  //   { url: '/videos', title: 'VIDEOS' },
  //   { url: '/warranty', title: 'WARRANTY' },
  //   { url: '/blog', title: 'BLOG' }
  // ];

  //const path = usePath();
  //const { pathname } = useRouter();

  // const isCurrent = (link) => {
  //   return pathname && pathname.toLowerCase() === link.toLowerCase();
  // }

  // const linkElts = links.map((link, index) =>
  //   <li key={index} className={`bottomMenu__li ${isCurrent(link.url) ? 'selected' : ''}`}>
  //     <SiteLink to={link.url} className={`bottomMenu__link bottomMenu__link--${index}`}>
  //       <div className="icon"></div>
  //       <div>{link.title}</div>
  //     </SiteLink>
  //   </li>
  // );

  // const bottomMenu = useRef(null);
  // useLayoutEffect(() => {
  //   //console.log('useLayoutEffect');
  //   const slider = new BottomDrawer(bottomMenu.current);
  //   setTimeout(() => slider.init(), 1000)
  // }, [])

  return (
    <>
      <nav className="bottomMenu">
        {/* <div className="bottomMenu__bar">
          <div className="bottomMenu__line1"></div>
          <div className="bottomMenu__line2"></div>
        </div> */}
        {/* <div className="bottomMenu__bg"></div> */}
        {/* <ul className="bottomMenu__links">
          {linkElts}
        </ul> */}
      </nav>
      {/* <div className="bottomMenuPadding"></div> */}
    </>
  );
}