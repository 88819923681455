/** @jsx jsx */
import { jsx } from '@emotion/core';

import { useState, Fragment } from 'react';
import { SiteLink } from '../../components';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
//import { NavBar } from './navBar';
import { BackButton } from '../backButton/backButton';
//import { Hamburger } from '../hamburger/hamburger';
import style from './topHeaderStyle';

export function TopHeader(props) {
  //const [menuOpened, setMenuOpened] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  useScrollPosition(({ prevPos, currPos }) => {
    //console.log(currPos.x)
    //console.log(currPos.y);
    if (currPos.y > 90) {
      setScrolled(true)
    } else {
      setScrolled(false)
    }
    //setScrolled(currPos.y > 90)
  }, [], null, true)

  // function toggleMenu(e) {
  //   setMenuOpened(!menuOpened);
  // }

  // function onCloseMenu() {
  //   setMenuOpened(false);
  // }

  return (
    <Fragment>
      <header css={[style.top, scrolled && style.topScrolled]} id="top">
        <BackButton/>
        <SiteLink to="/" css={[style.logo, scrolled && style.logoScrolled]}>
          <img css={style.logoImg} src="/assets/logos/logo.png" alt="logo" />
        </SiteLink >

        {/* <Hamburger opened={menuOpened} toggleMenu={toggleMenu} scrolled={scrolled}/>
        <NavBar open={menuOpened} onCloseMenu={onCloseMenu}></NavBar> */}
      </header>
    </Fragment>
  );
}